import { css } from '@emotion/react'
import { graphql, useStaticQuery } from 'gatsby'
import { ComponentPropsWithoutRef } from 'react'

import { SocialLink } from '@/components/SocialLink'
import { BrearleyLogoStacked, Seal } from '@/features/brearley-logo'
import { DatoLink } from '@/features/dato-link'
import { mq } from '@/theme/mixins'
import { colors } from '@/theme/variables'

export const Footer = ({
  ...props
}: ComponentPropsWithoutRef<'footer'>): JSX.Element => {
  const { datoCmsMetadataModel: meta, datoCmsFooterNav: nav } =
    useStaticQuery<Queries.FooterQuery>(graphql`
      query Footer {
        datoCmsMetadataModel {
          streetAddress1
          streetAddress2
          cityStateZip
          phone
          fax
          email
          socials {
            ...SocialLink
          }
        }
        datoCmsFooterNav {
          links {
            ...PageLink
          }
        }
      }
    `)
  const styles = {
    footer: css`
      background: ${colors.gray10};
      position: relative;
      display: grid;
      grid-template-columns: 2fr 1fr 1fr;
      grid-column-gap: var(--gtr-36);
      align-items: flex-start;
      padding: var(--row-54) var(--margin);
      color: #fff;
      border-top: 2rem solid #000;
      ${mq().m} {
        grid-template-columns: 1fr 1fr 1fr;
      }
      ${mq().ms} {
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 2em;
        justify-content: flex-start;
      }
      ${mq().s} {
        grid-template-columns: auto;
        justify-content: stretch;
      }
    `,
    list: css`
      margin: 1em 0;
      padding: 0;
      list-style: none;
      font-size: var(--fs-18);
      font-weight: 300;
      a {
        color: currentColor;
        text-decoration: none;
        padding: 0.25em 0;
        margin: 0;
        line-height: 1.25;
        display: inline-block;
        @media (hover: hover) {
          &:hover {
            color: ${colors.redLight};
          }
        }
      }
      div {
        padding: 0.25em 0;
        line-height: 1.25;
      }
      ${mq().s} {
        margin-bottom: 0;
        text-align: center;
      }
    `,
    contactList: css`
      li {
        margin-bottom: 0.75em;
      }
    `,
    social: css`
      && {
        margin-bottom: 0;
      }
    `,
    address: css`
      && {
        line-height: 1;
        margin-bottom: 1em;
        a,
        div {
          line-height: inherit;
        }
      }
    `,
    navList: css`
      font-size: var(--fs-16);
      li {
        margin-bottom: 1em;
      }
      ${mq().s} {
        &:before {
          content: '';
          display: inline-block;
          width: 2em;
          margin-bottom: 2em;
          height: 2px;
          background: ${colors.redLight};
        }
      }
    `,
    logo: css`
      font-size: var(--fs-48);
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin: 0;
      max-width: 100%;
      min-width: 10em;
      ${mq().m} {
        font-size: var(--fs-36);
      }
      ${mq().ms} {
        font-size: var(--fs-48);
        grid-column: 1 / -1;
        margin-bottom: 0.5em;
        justify-self: stretch;
      }
      ${mq().s} {
        justify-content: center;
        min-width: 100%;
      }
    `,
    seal: css`
      max-height: 4em;
      width: auto;
      margin-right: 0.5em;
    `,
  }
  return (
    <footer
      css={styles.footer}
      {...props}
    >
      <h2 css={styles.logo}>
        <Seal
          css={styles.seal}
          color={colors.redLight}
        />
        <BrearleyLogoStacked />
      </h2>
      <ul css={[styles.list, styles.contactList]}>
        <li css={styles.address}>
          <a
            href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
              meta?.streetAddress1 + ', ' + meta?.cityStateZip
            )}`}
            target="_blank"
            rel="noreferrer"
          >
            {meta?.streetAddress1}
          </a>
          <br />
          <a
            href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
              meta?.streetAddress2 + ', ' + meta?.cityStateZip
            )}`}
            target="_blank"
            rel="noreferrer"
          >
            {meta?.streetAddress2}
          </a>
          <br />
          <div>{meta?.cityStateZip}</div>
        </li>
        <li>
          T:{' '}
          <a
            href={`tel:${meta?.phone?.replace(/\D/, '')}`}
            target="_blank"
            rel="noreferrer"
          >
            {meta?.phone}
          </a>
        </li>
        {meta?.fax && (
          <li>
            F:{' '}
            <a
              href={`tel:${meta?.fax.replace(/\D/, '')}`}
              target="_blank"
              rel="noreferrer"
            >
              {meta?.fax}
            </a>
          </li>
        )}
        <li>
          <a
            href={`mailto:${meta?.email}`}
            target="_blank"
            rel="noreferrer"
          >
            {meta?.email}
          </a>
        </li>
        {meta?.socials?.map((social, i) => (
          <li
            key={i}
            css={styles.social}
          >
            <SocialLink
              data={social}
              layout="TEXT"
            />
          </li>
        ))}
      </ul>
      <ul css={[styles.list, styles.navList]}>
        {nav?.links?.map((link, i) => (
          <li key={i}>
            <DatoLink data={link} />
          </li>
        ))}
      </ul>
    </footer>
  )
}
